import React from "react";

export default React.memo(function AnnouncementBar({
  message,
  bgColor = "primary",
  textColor = "dark",
  button,
  buttonCta,
  buttonLink,
  showPattern = false,
}) {
  return (
    <div className="announcement-wrapper">
      <div
        className={`py-3 bg-${bgColor} ${
          showPattern ? "bg-pattern" : ""
        } @@classList`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className={`text-center text-${textColor} bold d-flex justify-content-center flex-column flex-lg-row`}
              >
                <span className="letter-spacing-xl">{message}</span>

                {button && buttonLink && (
                  <a
                    href={buttonLink}
                    target="_blank"
                    className="btn btn-dark btn-xxs ml-5 mt-5 mt-lg-0 mb-0"
                  >
                    {buttonCta ?? "Click here"}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
