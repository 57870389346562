import React, { useEffect } from "react";
import LoginForm from "./LoginForm";
import { useAuth } from "../services/hooks/useAuth";
import closeModals from "../util/closeModals";
import { useSnackbar } from "../services/hooks/useSnackbar";
import { useRouter } from "next/router";

export default React.memo(function LoginModal() {
  const { user, login, loginError, loginStatus } = useAuth();
  const router = useRouter();

  const { addSnackbar } = useSnackbar();

  const onLogin = (data) => {
    login(data);
  };

  useEffect(() => {
    if (loginStatus === "ready" && user) {
      closeModals();

      addSnackbar("You're now signed in.", {
        type: "success",
      });

      if (router.pathname.includes("/survey")) {
        router.reload();
      }
    }
  }, [loginStatus]);

  return (
    <div
      className="modal fade"
      id="modalLogin"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fe fe-x" aria-hidden="true"></i>
          </button>

          <div className="modal-header line-height-fixed font-size-lg">
            <strong className="mx-auto">Sign into your account</strong>
          </div>

          <div className="modal-body">
            <LoginForm
              onLogin={onLogin}
              loginError={loginError}
              loginStatus={loginStatus}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
